<template>
  <layout class="main-cols">
    <div class="container is-fluid lg:ml-0">
      <tabs :tabs="tabs" v-model="activeTab" type="default" />
      <div v-if="activeTab == 0">
        <orden-prelacion />
      </div>
      <div v-if="activeTab == 1">
        <cuentas-bancarias />
      </div>
      <div v-if="activeTab == 2">
        <permisos :submodulo="submoduloPagosEspeciales" />
      </div>
      <div v-if="activeTab == 3">
        <div class="columns is-multiline">
          <div class="column">
            <p class="text-left text-2xl font-medium">Extras</p>
          </div>
          <div class="column text-right">
            <b-button @click="setExtras" type="is-primary"
              >Guardar información extra</b-button
            >
          </div>
          <div class="column is-12 text-left">
            <div class="field">
              <b-switch
                :value="true"
                type="is-success"
                v-model="notificar_clientes"
              >
                Enviar notificación de pago a clientes al cerrar día
              </b-switch>
            </div>
            <div class="field">
              <b-field label="Tamaño de impresión ticket">
                <b-select
                  placeholder="Selecciona un tamaño"
                  v-model="ticket_size"
                >
                  <option
                    v-for="size in paper_sizes"
                    :value="size.size"
                    :key="size.size"
                  >
                    {{ size.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import Tabs from "@/components/generals/Tabs";
import CuentasBancarias from "@/components/configuracion/tesoreria/CuentasBancarias";
import OrdenPrelacion from "@/components/configuracion/tesoreria/OrdenPrelacion";
import Permisos from "@/components/configuracion/Permisos";
import { mapGetters } from "vuex";

export default {
  name: "Tesoreria",
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          label: "Prelación global"
        },
        {
          label: "Cuentas bancarias"
        },
        {
          label: "Permisos"
        },
        {
          label: "Extras"
        }
      ],
      notificar_clientes: false,
      ticket_size: null,
      paper_sizes: [
        {
          size: "216x279",
          name: "Carta"
        },
        {
          size: "216x356",
          name: "Oficio"
        },
        {
          size: "80",
          name: "80mm ancho (Termica)"
        },
        {
          size: "58",
          name: "58mm ancho (Termica)"
        },
        {
          size: "45",
          name: "45mm ancho (Termica)"
        }
      ]
    };
  },
  methods: {
    //Función que manda llamar a API para agregar datos extras
    setExtras() {
      this.$store.dispatch("empresa/updateSofom", {
        notificar_clientes: this.notificar_clientes,
        ticket_size: this.ticket_size
      });
    }
  },
  computed: {
    ...mapGetters(["getSofom"]),
    ...mapGetters("catalogos", ["getSubmodulos"]),
    submoduloPagosEspeciales() {
      return this.getSubmodulos.find(x => x.nombre == "Pagos especiales").id;
    }
  },
  mounted() {
    if (this.getSofom) {
      this.notificar_clientes = this.getSofom.notificar_clientes;
      this.ticket_size = this.getSofom.ticket_size;
    }
    if (this.getSubmodulos == null) {
      this.$store.dispatch("catalogos/getSubmodulos");
    }
  },
  components: {
    OrdenPrelacion,
    Tabs,
    CuentasBancarias,
    Permisos
  }
};
</script>
