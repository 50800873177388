<template>
  <div>
    <div class="columns">
      <div class="column">
        <p class="text-left text-2xl font-medium">Permisos</p>
      </div>
    </div>
    <div class="columns pl-4" v-if="typeof submodulo === 'number'">
      <div class="column">
        <b-collapse
          v-if="getPersonasPermisosSubmodulo && getSubmodulo"
          aria-id="submodulo"
          class="has-border-radius has-background-white-ter shadow-none"
          animation="slide"
          :open.sync="open_individual"
        >
          <div
            slot="trigger"
            class="text-left"
            role="button"
            aria-controls="submodulo"
          >
            <p class="text-xl font-medium p-3">{{ getSubmodulo.nombre }}</p>
          </div>
          <div class="card-content">
            <b-field>
              <b-input
                type="search"
                v-model="ejecutivo_search"
                placeholder="Buscar Empleado..."
              />
            </b-field>
            <div class="content columns mt-2">
              <div class="column text-left">
                <p class="text-xl font-medium">Empleados sin permiso</p>
                <div class="min-h-5rem overflow-y-auto max-h-12rem">
                  <div
                    v-for="empleado in empleados_sin_permiso_filtered"
                    :key="empleado.personas_id"
                    class=" my-2 p-1 pl-4 has-background-white text-left has-border-radius"
                  >
                    <div class="columns is-vcentered">
                      <div class="column is-8">
                        <p>{{ empleado.alias.trim() }}</p>
                      </div>
                      <div class="column text-right mr-2">
                        <b-button
                          type="is-primary"
                          size="is-small"
                          @click="givePermiso(empleado)"
                        >
                          Dar permiso
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div
                  class="card has-border-radius shadow-none border-2 p-4 text-left"
                >
                  <p class="font-medium text-xl">Empleados con permiso</p>
                  <div class="min-h-5rem overflow-y-auto max-h-12rem">
                    <div
                      v-for="empleado in empleados_con_permiso_filtered"
                      :key="empleado.personas_id"
                      class=" my-2 p-1 pl-4 has-background-primary-04 text-left has-border-radius"
                    >
                      <div class="columns is-vcentered">
                        <div class="colum is-8">
                          <p class="has-text-primary">
                            {{ empleado.alias.trim() }}
                          </p>
                        </div>
                        <div class="column text-right mr-2">
                          <b-button
                            type="is-danger"
                            size="is-small"
                            icon-left="times"
                            @click="removePermiso(empleado)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer class="card-footer p-3 flex items-center justify-end">
            <b-button type="is-primary" @click="changePermisos"
              >Aceptar cambios</b-button
            >
          </footer>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Permisos",
  data() {
    return {
      open_individual: true,
      empleados_con_permiso: [],
      ejecutivo_search: "",
      empleados_sin_permiso: []
    };
  },
  props: {
    submodulo: [Number, Array, null]
  },
  methods: {
    /*
     Función que agrega al empleado al array de empleados con permiso y lo quita del array 
     de empleados sin permiso
     @empleado(Object)
     */
    givePermiso(empleado) {
      this.empleados_con_permiso.push(empleado);
      this.empleados_sin_permiso = this.empleados_sin_permiso.filter(
        e => e.personas_id !== empleado.personas_id
      );
    },
    /*
     Función que agrega al empleado al array de empleados sin permiso y lo quita del array 
     de empleados con permiso
     @empleado(Object)
    */
    removePermiso(empleado) {
      this.empleados_sin_permiso.push(empleado);
      this.empleados_con_permiso = this.empleados_con_permiso.filter(
        e => e.personas_id !== empleado.personas_id
      );
    },
    /*
     Función que envia a la API los empleados con permisos para su actualizacion
    */
    changePermisos() {
      let empleados_permisos_new = this.empleados_con_permiso.map(
        empleado => empleado.personas_id
      );
      this.$store.dispatch("empresa/changePermisosPersonas", {
        personas: empleados_permisos_new,
        submodulo_id: this.getSubmodulo.id
      });
    }
  },
  computed: {
    ...mapGetters("empresa", ["getPersonasPermisosSubmodulo"]),
    ...mapGetters("catalogos", ["getSubmodulo", "getEjecutivos"]),
    /*
     propiedad que se actualiza en tiempo real en base al buscador para filtrar los empleados sin permiso
    */
    empleados_sin_permiso_filtered() {
      return this.empleados_sin_permiso.filter(
        option =>
          option.alias
            .toString()
            .toUpperCase()
            .indexOf(this.ejecutivo_search.toString().toUpperCase()) >= 0
      );
    },
    /*
     propiedad que se actualiza en tiempo real en base al buscador para filtrar los empleados con permiso
    */
    empleados_con_permiso_filtered() {
      return this.empleados_con_permiso.filter(
        option =>
          option.alias
            .toString()
            .toUpperCase()
            .indexOf(this.ejecutivo_search.toString().toUpperCase()) >= 0
      );
    }
  },
  mounted() {
    this.$store.dispatch("catalogos/getEjecutivos").then(data => {
      if (typeof this.submodulo == "number") {
        this.$store.dispatch("catalogos/getSubmodulo", this.submodulo);
        this.$store
          .dispatch("empresa/getPersonasPermisosSubmodulo", this.submodulo)
          .then(dataPermisos => {
            this.empleados_con_permiso = dataPermisos.map(empleado => {
              return {
                alias: empleado.alias.trim(),
                personas_id: empleado.id
              };
            });
            let empleados_permisos = this.empleados_con_permiso.map(
              empleado => empleado.personas_id
            );
            let ejecutivos = data;
            if (empleados_permisos.length > 0) {
              ejecutivos = ejecutivos.filter(
                empleado => !empleados_permisos.includes(empleado.personas_id)
              );
            }
            this.empleados_sin_permiso = ejecutivos;
          });
      }
    });
  }
};
</script>
